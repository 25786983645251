.header{
    background-color: lightgrey;
    padding: 25px 0px;
}
.bank-name{
    font-size: 28px;
    padding: 12px 12px 20px 12px !important;
    box-sizing: border-box;
}
.bank-logo{
    text-align: end;
    padding-inline-end: 20px !important;
    box-sizing: border-box;
}
.page-title{
    text-align: start;
}
.bank-table{
    text-align: start;
}