@import './_variables.scss';
.app{
    position: relative;
}

.quick-donation-bubble{
    direction: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: fixed;
    z-index: 1000000;
    top: 40vh;
    right: -310px;
    transition: right 1s linear;
    &[dir="rtl"]{
        flex-direction: row-reverse;
    }
    .bubble{
        background-color: $secondary-color;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 15px;
        font-weight: 600;
        color: #fff;
        text-align: center;
        max-height: 70px;
        
    }
    .bubble:hover{
        cursor: pointer;
    }
    .form{
        border: 1px solid lightgrey;
        padding: 10px;
        background-color: #fff;
    }
    .action{
        justify-content: center;
        display: flex;
        .btn-primary{
            background-color: $primary-color;
            border-color: $primary-color;
            color: black;
            font-weight: 600;
            padding: 10px 45px;
        }
    }
}

.quick-donation-bubble.active{
    right: 0px;
    transition: right 1s linear;
}